export const pcRouter = [
  {
    path: "/home",
    name: "home",
    component: function () {
      return import("../views/home/home.vue");
    },
    meta: { type: "pc" },
  },
  // 公司简介
  {
    path: "/usJoin",
    name: "usJoin",
    component: function () {
      return import("../views/usJoin/usJoin.vue");
    },
    meta: { type: "pc" },
  },
  // 招贤纳士
  {
    path: "/recruit",
    name: "recruit",
    component: function () {
      return import("../views/recruit/recruit.vue");
    },
    meta: { type: "pc" },
  },
  //中医科技详情页面
  {
    path: "/recruitInfo",
    name: "recruitInfo",
    component: function () {
      return import("../views/recruit/recruitInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 荣誉资质
  {
    path: "/honor",
    name: "honor",
    component: function () {
      return import("../views/honor/honor.vue");
    },
    meta: { type: "pc" },
  },
  //课程内容
  {
    path: "/course",
    name: "course",
    component: function () {
      return import("../views/course/course.vue");
    },
    meta: { type: "pc" },
  },
  //课程详情
  {
    path: "/courseInfo",
    name: "courseInfo",
    component: function () {
      return import("../views/course/courseInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 师资团队
  {
    path: "/teaching",
    name: "teaching",
    component: function () {
      return import("../views/teaching/teaching.vue");
    },
    meta: { type: "pc" },
  },
  // 中医科技
  {
    path: "/zykj",
    name: "zykj",
    component: function () {
      return import("../views/zykj/zykj.vue");
    },
    meta: { type: "pc" },
  },
  //中医传承
  {
    path: "/zycc",
    name: "zycc",
    component: function () {
      return import("../views/zycc/zycc.vue");
    },
    meta: { type: "pc" },
  },
  // 特聘专家
  {
    path: "/tpzj",
    name: "tpzj",
    component: function () {
      return import("../views/tpzj/tpzj.vue");
    },
    meta: { type: "pc" },
  },
  // 主讲专家
  {
    path: "/zjzj",
    name: "zjzj",
    component: function () {
      return import("../views/zjzj/zjzj.vue");
    },
    meta: { type: "pc" },
  },
  //就业服务
  {
    path: "/jiuyefuwu",
    name: "jiuyefuwu",
    component: function () {
      return import("../views/jiuyefuwu/jiuyefuwu.vue");
    },
    meta: { type: "pc" },
  },
  //优秀学员
  {
    path: "/yxxy",
    name: "yxxy",
    component: function () {
      return import("../views/yxxy/yxxy.vue");
    },
    meta: { type: "pc" },
  },
  //创业先锋
  {
    path: "/cyxf",
    name: "cyxf",
    component: function () {
      return import("../views/cyxf/cyxf.vue");
    },
    meta: { type: "pc" },
  },
  //顾问团队页面
  {
    path: "/gwtd",
    name: "gwtd",
    component: function () {
      return import("../views/gwtd/gwtd.vue");
    },
    meta: { type: "pc" },
  },
  // 就业信息
  {
    path: "/jyxx",
    name: "jyxx",
    component: function () {
      return import("../views/jyxx/jyxx.vue");
    },
    meta: { type: "pc" },
  },
  // 通知公告
  {
    path: "/notice",
    name: "notice",
    component: function () {
      return import("../views/notice/notice.vue");
    },
    meta: { type: "pc" },
  },
  //中医科技详情页面
  {
    path: "/zykjInfo",
    name: "zykjInfo",
    component: function () {
      return import("../views/zykj/zykjInfo.vue");
    },
    meta: { type: "pc" },
  },
  //中医传承详情页面
  {
    path: "/zyccInfo",
    name: "zyccInfo",
    component: function () {
      return import("../views/zycc/zyccInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 职业技能详情页
  {
    path: "/zyjnInfo",
    name: "zyjnInfo",
    component: function () {
      return import("../views/zyjn/zyjnInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 师资团队详情信息
  {
    path: "/teachingInfo",
    name: "teachingInfo",
    component: function () {
      return import("../views/teaching/teachingInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 新闻动态详情信息
  {
    path: "/noticeInfo",
    name: "noticeInfo",
    component: function () {
      return import("../views/notice/noticeInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 优秀学员详情信息
  {
    path: "/yxxyInfo",
    name: "yxxyInfo",
    component: function () {
      return import("../views/yxxy/yxxyInfo.vue");
    },
    meta: { type: "pc" },
  },
  // 创业先锋详情页面
  {
    path: "/cyxfInfo",
    name: "cyxfInfo",
    component: function () {
      return import("../views/cyxf/cyxfInfo.vue");
    },
    meta: { type: "pc" },
  },
  //顾问团队详情页面
  {
    path: "/gwtdInfo",
    name: "gwtdInfo",
    component: function () {
      return import("../views/gwtd/gwtdInfo.vue");
    },
    meta: { type: "pc" },
  },
  //特聘专家详情页面
  {
    path: "/tpzjInfo",
    name: "tpzjInfo",
    component: function () {
      return import("../views/tpzj/tpzjInfo.vue");
    },
    meta: { type: "pc" },
  },
  //主讲专家详情页面
  {
    path: "/zjzjInfo",
    name: "zjzjInfo",
    component: function () {
      return import("../views/zjzj/zjzjInfo.vue");
    },
    meta: { type: "pc" },
  },
  //就业信息详情页面
  {
    path: "/jyxxInfo",
    name: "jyxxInfo",
    component: function () {
      return import("../views/jyxx/jyxxInfo.vue");
    },
    meta: { type: "pc" },
  },
];
