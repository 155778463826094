export const menuList = [
  { name: "网站首页", path: "/home", key: "home", activePath: "/home" },
  {
    name: "师资团队", 
    key: "expertSpeaker",
    children: [
      // 子菜单项列表
      {
        name: "主讲专家",
        path: "/expert/expertSpeaker",
        key: "expertSpeaker",
      },
      {
        name: "顾问团队",
        path: "/expert/advisoryTeam",
        key: "advisoryTeam",
      },
    ],
  },
];
