export const mobileRouter = [
  {
    path: "/home", // 首页
    name: "MobileIndex",
    component: () => import("../mobilePages/home/home.vue"),
    meta: { type: "mobile" },
  },
  {
    path: "/expert", // 父路由
    meta: { type: "mobile" },
    children: [
      {
        path: "expertSpeaker", // 子路由路径
        name: "MobileExpertSpeaker",
        component: () => import("../mobilePages/expert/expertSpeaker.vue"),
        meta: { type: "mobile" },
      },
      {
        path: "advisoryTeam", // 子路由路径
        name: "MobileAdvisoryTeam",
        component: () => import("../mobilePages/expert/advisoryTeam.vue"),
        meta: { type: "mobile" },
      }
    ]
  }

];
